import { useState } from "react"

import { CreateHomeBlocker } from "src/components/FeatureBlockers/CreateHomeBlocker"
import { HomeGroupBuildingCreateHomeConfirmationStep } from "src/components/HomeGroups/HomeGroupBuildingCreateHomeWizard/HomeGroupBuildingCreateHomeConfirmationStep"
import {
  BUILDING_HOME_INFORMATION_FORM,
  HomeGroupBuildingCreateHomeInformationStep,
} from "src/components/HomeGroups/HomeGroupBuildingCreateHomeWizard/HomeGroupBuildingCreateHomeInformationStep"
import { HomeGroupBuildingCreateHomeTypeStep } from "src/components/HomeGroups/HomeGroupBuildingCreateHomeWizard/HomeGroupBuildingCreateHomeTypeStep"
import { SelectMonitoringPresetStep } from "src/components/Homes/CreateHomeWizard/SelectMonitoringPresetStep"
import { useCreateHomeWizard } from "src/components/Homes/CreateHomeWizard/useCreateHomeWizard"
import { usePostImportUnitClicked } from "src/data/analytics/queries/homegroupAnalyticsQueries"
import { useFetchHomeGroup } from "src/data/homeGroups/queries/homeGroupQueries"
import { THomeType } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { TSelectedPresetId } from "src/data/profileSettings/types/monitoringPresetTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { FullscreenWizard } from "src/ui/Wizard/FullscreenWizard"
import { IWizardStep } from "src/ui/Wizard/wizardTypes"
import { ErrorService } from "src/utils/ErrorService"

export function HomeGroupBuildingCreateHomeWizard({
  homeGroupId,
}: {
  homeGroupId: string
}) {
  // #region Analytics
  const postImportUnitClicked = usePostImportUnitClicked()
  // #endregion Analytics

  const { t, langKeys } = useTranslate()
  const { navigate, goBack } = useRouter()

  const { orgId } = useOrganization()

  const [selectedHomeType, setSelectedHomeType] = useState<THomeType>("private")
  const [homeName, setHomeName] = useState("")
  const [floor, setFloor] = useState("")
  const [aptNumber, setAptNumber] = useState("")
  const [selectedPresetId, setSelectedPresetId] = useState<TSelectedPresetId>({
    icm: "",
    noise: "",
  })

  const [currentStep, setCurrentStep] = useState(0)

  const {
    isLoadingCreateHomeEstimate,
    hasCreateHomeEstimateError,
    showBlocker,
    blockerReason,
    hasPlanUnitPerHome,
    checkedTerms,
    onCheckTerms,
    checkedTermsError,
    createHomeEstimate,
    isCreatingHome,
    isCreateHomeError,
    setCreateHomeError,
    createHome,
  } = useCreateHomeWizard({ quantity: 1 })

  const fetchHomeGroup = useFetchHomeGroup({
    orgId,
    homeGroupId,
  })

  const isLoading = isLoadingCreateHomeEstimate || fetchHomeGroup.isLoading
  const hasError = hasCreateHomeEstimateError || fetchHomeGroup.isError

  function handleCreateHome() {
    if (!fetchHomeGroup.data?.address) {
      ErrorService.captureException(
        "Home group address is missing when creating a home in a building"
      )
      setCreateHomeError(true)
      return
    }

    if (!fetchHomeGroup.data?.location) {
      ErrorService.captureException(
        "Home group location is missing when creating a home in a building"
      )
      setCreateHomeError(true)
      return
    }

    createHome({
      name: homeName,
      location: fetchHomeGroup.data.location,
      address: {
        ...fetchHomeGroup.data.address,
        floor_number: floor,
        apartment_number: aptNumber,
      },
      homegroup_id: homeGroupId,
      noise_profile_id: selectedPresetId.noise,
      space_use_type: selectedHomeType,
      onSuccess: (data) => {
        navigate(Routes.Home.location(data.home_id))
      },
    })
  }

  const stepper: IWizardStep[] = [
    {
      component: (
        <HomeGroupBuildingCreateHomeTypeStep
          selectedHomeType={selectedHomeType}
          setSelectedHomeType={(homeType) => {
            setHomeName("")
            setFloor("")
            setAptNumber("")
            setSelectedHomeType(homeType)
          }}
          loading={isLoading}
          error={hasError}
        />
      ),
      footer: (
        <div>
          <MText variant="heading3">
            {t(langKeys.home_group_create_home_import_suggestion)}
          </MText>
          <InternalLink
            to={Routes.HomeGroupBuildingImportHomes.location(homeGroupId)}
            color="primary"
            beforeNav={() => postImportUnitClicked.mutate()}
          >
            {t(langKeys.import_homes)}
          </InternalLink>
        </div>
      ),
      onBack: () =>
        goBack({ defaultPath: Routes.HomeGroupDetails.location(homeGroupId) }),
      nextButtonLabel: t(langKeys.continue),
      nextButtonProps: {
        loading: isLoading,
        disabled: hasError,
      },
      hideBackButton: true,
    },
    {
      component: (
        <HomeGroupBuildingCreateHomeInformationStep
          homeGroupName={fetchHomeGroup.data?.name || ""}
          homeType={selectedHomeType}
          homeName={homeName}
          setHomeName={setHomeName}
          floor={floor}
          setFloor={setFloor}
          aptNumber={aptNumber}
          setAptNumber={setAptNumber}
          onSubmit={() => {
            setCurrentStep((currStep) => currStep + 1)
          }}
        />
      ),
      nextButtonLabel: t(langKeys.continue),
      nextButtonProps: {
        form: BUILDING_HOME_INFORMATION_FORM,
      },
    },
    {
      component: (
        <SelectMonitoringPresetStep
          selectedPresetId={selectedPresetId}
          setSelectedPresetId={setSelectedPresetId}
        />
      ),
      nextButtonLabel: t(langKeys.continue),
      nextButtonProps: {
        disabled: !selectedPresetId.noise,
      },
    },
    {
      component: (
        <HomeGroupBuildingCreateHomeConfirmationStep
          homeGroupName={fetchHomeGroup.data?.name || ""}
          floor={floor}
          createHomeEstimate={createHomeEstimate}
          checkedTerms={checkedTerms}
          onCheckTerms={onCheckTerms}
          checkedTermsError={checkedTermsError}
          hideBillingInformation={!hasPlanUnitPerHome}
          error={isCreateHomeError}
          onEditClick={() => setCurrentStep(1)}
        />
      ),
      onNext: handleCreateHome,
      nextButtonLabel: t(langKeys.create_home),
      nextButtonProps: {
        loading: isCreatingHome,
      },
    },
  ]

  if (showBlocker) {
    return <CreateHomeBlocker blockerReason={blockerReason} />
  }

  return (
    <FullscreenWizard
      title={t(langKeys.create_home)}
      currentStep={currentStep}
      steps={stepper}
      onClose={() => navigate(Routes.HomeGroupDetails.location(homeGroupId))}
      onNext={() => setCurrentStep((currStep) => currStep + 1)}
      onBack={() => setCurrentStep((currStep) => currStep - 1)}
    />
  )
}
